////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 251, 251, 1);
}
.bg-secondaryColor {
  background-color: rgba(30, 34, 54, 1);
}
.bg-thirdColor {
  background-color: rgba(14, 15, 19, 1);
}
.bg-fourthColor {
  background-color: rgba(38, 84, 123, 1);
}
.bg-fifthColor {
  background-color: rgba(38, 84, 123, 0.7);
}
.bg-sixthColor {
  background-color: rgba(114, 112, 113, 1);
}
.bg-seventhColor {
  background-color: rgba(114, 112, 113, 1);
}
